'use client'

import React, { useState } from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const Faq = ({data, style = {}, classname= ""}) => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div id='faq' style={style} className={classname}>
        <div className='container'>
            <h2 className="title gradientText mainHeading sectionTitle">frequently asked questions</h2>
          <div className="parent">
            {data.map((item, index) => (
              <>
                <div className={`item ${activeIndex === index ? 'active' : ''}`} key={index}>
                  <div className='question'>
                    <span className="title gradientText" onClick={() => toggleItem(index)}>
                      {item.question}
                    </span>
                    <img onClick={() => toggleItem(index)} src={activeIndex === index ? `${process.env.NEXT_PUBLIC_WEBSITE_URL}/faq_golden_open.svg` : `${process.env.NEXT_PUBLIC_WEBSITE_URL}/faq_golden_closed.svg`}  />
                  </div>
                  <div className={`answer ${activeIndex === index ? 'active' : ''}`}>
                    <BlocksRenderer content={item.answer} />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;